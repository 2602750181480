import { useLazyQuery } from '@apollo/client';
import Router from 'next/router';
import {
  Dispatch,
  FormEvent,
  SetStateAction,
  useEffect,
  useState
} from 'react';
import { FIND_BOOKING, FIND_BOOKING_INTERFACE } from '../../../api/myTour';
import axios from 'axios';
import Image from 'next/image';

interface ModalData {
  open: boolean;
  setOpenModal: Dispatch<SetStateAction<boolean>>;
}

function Modal({ open, setOpenModal }: ModalData) {
  const [inputValue, setInputValue] = useState('');
  const [error, setError] = useState<boolean>(false);
  const [attractionLoading, setAttractionLoading] = useState<boolean>(false);
  const [findBooking, { loading: tourloading }] =
    useLazyQuery<FIND_BOOKING_INTERFACE>(FIND_BOOKING);
  const handleForm = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (inputValue.startsWith('T')) {
      const apiUrl = `${process.env.NEXT_PUBLIC_FIREBASE_CONNECTION}/attraction-booking/${inputValue}`;

      try {
        setAttractionLoading(true);
        const response = await axios.get(apiUrl);
        setInputValue('');
        setOpenModal(false);
        setError(false);
        Router.push(`/my-bookings/${inputValue}`);
      } catch (error) {
        setError(true);
        console.error(error);
      } finally {
        setAttractionLoading(false);
      }
    } else {
      findBooking({
        variables: {
          id: inputValue
        },
        onCompleted: value => {
          if (!value.boatnew_booking[0]) {
            setError(true);
            return;
          }
          setInputValue('');
          setOpenModal(false);
          setError(false);
          Router.push(`/my-bookings/${value.boatnew_booking[0].id}`);
        }
      });
    }
  };
  useEffect(() => {
    if (!open) {
      setInputValue('');
      setError(false);
    }
  }, [open]);

  {
    /* Modal Wrapper z-index should be 1150 or in between 1100 and 1200 */
  }

  return (
    <div
      className={`fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center xl:items-start py-12  min-h-screen z-[1150] ${
        !open && 'hidden'
      } `}
      onClick={e => {
        setOpenModal(false);
      }}
    >
      <div
        className="bg-white p-4  w-11/12 md:w-9/12 lg:w-8/12   xl:w-2/5   2xl:max-w-[30%] text-center"
        onClick={e => e.stopPropagation()}
      >
        <span
          className="cursor-pointer flex justify-end"
          onClick={() => setOpenModal(false)}
        >
          <Image
            src={'/cross-icon.svg'}
            alt="lock-icon"
            width={33}
            height={33}
            className=""
          />
        </span>
        <div className="py-5">
          <p className="text-lg font-bold mb-2 text-center ">My Bookings</p>
          <p className="mb-4 ">
            Please Enter Your Booking Code To Find Your Booked Tour!
          </p>
          <form onSubmit={e => handleForm(e)}>
            <div className="mb-4">
              {error && (
                <label
                  className="block  font-bold mb-2 text-red-600 "
                  htmlFor="inputField"
                >
                  Booking Not Found, Please Enter A Valid Booking Code
                </label>
              )}
              <input
                className=" border rounded w-full  px-3 pt-4 pb-3  bg-slate-100  text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                id="inputField"
                placeholder="Booking Number"
                type="text"
                value={inputValue}
                onChange={e => setInputValue(e.target.value)}
                minLength={5}
                maxLength={7}
              />
            </div>
            <div className="flex w-full justify-end ">
              <button
                className={`bg-[#1A90D0] hover:bg-blue-500 text-white  py-2 px-4 rounded-md focus:outline-none focus:shadow-outline ${
                  tourloading || (attractionLoading && ' animate-pulse')
                } `}
                type="submit"
              >
                {tourloading || attractionLoading
                  ? 'Please wait..'
                  : 'Find Booking'}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
export default Modal;
