const navigationalSchema = {
  '@context': 'https://schema.org/',
  '@type': 'ItemList',
  itemListElement: [
    {
      '@type': 'SiteNavigationElement',
      position: 1,
      name: 'Home',
      description:
        'Best Small Group Tours. Operating Across Canada and the United States',
      url: 'https://www.seesight-tours.com/'
    },
    {
      '@type': 'SiteNavigationElement',
      position: 2,
      name: ' Day Tours',
      description: 'Small Group Single Day Tours',
      url: 'https://www.seesight-tours.com/tours'
    },
    {
      '@type': 'SiteNavigationElement',
      position: 3,
      name: 'Our Cities',
      description:
        'Trip Shepperd Tours has day and multi-day tours in cities across North America',
      url: 'https://www.seesight-tours.com/cities'
    },
    {
      '@type': 'SiteNavigationElement',
      position: 4,
      name: 'Niagara Falls Tours USA',
      description:
        'Small-group tours and experiences in Niagara Falls, USA. The best things to see and do on the American-side of Niagara Falls.',
      url: 'https://www.seesight-tours.com/niagara-falls-usa-tours'
    },
    {
      '@type': 'SiteNavigationElement',
      position: 5,
      name: 'Niagara Falls Tours Canada',
      description:
        'Tours and Experiences in Niagara Falls, Canada. Private tours, walking tours, day tours, multi-day tours and shuttle services.',
      url: 'https://www.seesight-tours.com/niagara-falls-tours-canada'
    },
    {
      '@type': 'SiteNavigationElement',
      position: 6,
      name: 'Contact Us',
      description: 'Contact Trip Shepperd Tours',
      url: 'https://www.seesight-tours.com/contact'
    },
    {
      '@type': 'SiteNavigationElement',
      position: 7,
      name: 'About US',
      description: 'The Trip Shepperd Tours About',
      url: 'https://www.seesight-tours.com/about'
    },
    {
      '@type': 'SiteNavigationElement',
      position: 8,
      name: 'FAQs',
      description: 'Frequently Asked Questions',
      url: 'https://www.seesight-tours.com/faq'
    },
    {
      '@type': 'SiteNavigationElement',
      position: 9,
      name: 'Careers',
      description: 'A Great Career at Trip Shepperd Tours',
      url: 'https://www.seesight-tours.com/careers'
    }
  ]
};

export default navigationalSchema;
