import Head from 'next/head';
import { useEffect, useRef, useState } from 'react';
import AttractionCategories from '../../components/Landingpage/components/attractionCategories';
import { useSearchContext } from '../../utils/context/searchContext';
import algoliasearch from 'algoliasearch/lite';
import { InstantSearch } from 'react-instantsearch-core';
import { CustomSearchBox } from '../../shared-components/SearchBar/SearchBar';
import { AttractionCardType } from '../../types/components/attraction-card';
import { getOpeningHoursForCurrentDay } from '../../utils/getCurrentDayOpeningHours';
import { useDebounce } from '../../utils/debounce';
import {
  ELandingPage,
  GeoLoc,
  SearchDropdownAttraction
} from '../../types/pages/landing-page';
import L from 'leaflet';
import { useUpdateQueryParams } from '../../utils/useUpdateQueryParams';
import LandingPage from '../../components/Landingpage/landingpage';
import Footer from '../../layouts/Footer/footer';
import { RelatedBreadcrumbLinks } from '../../components/ThingsToDo/RelatedBreadcrumbLinks/RelatedBreadcrumbLinks';
import { RelatedUrl } from '../../types/pages/things-to-do';

// const Newsletter = dynamic(() => import('../../layouts/Newsletter/Newsletter'));

const searchClient = algoliasearch(
  process.env.NEXT_PUBLIC_ALGOLIA_APP_ID || '',
  process.env.NEXT_PUBLIC_ALGOLIA_API_KEY || ''
);
const attractionCategories = [
  {
    id: '0',
    label: 'All Attractions',
    value: 'All Attractions',
    grayIcon:
      'https://res.cloudinary.com/see-sight-tours/image/upload/v1713248139/icons-website/h8fhrijhtbxorqla8urd.svg',
    redIcon: '/all-tours-black.svg',
    icon: 'all-category-icon.svg'
  },
  {
    id: '1',
    label: 'Parks',
    value: 'Parks',
    grayIcon:
      'https://res.cloudinary.com/see-sight-tours/image/upload/v1713248307/icons-website/tt59iyazarjenohe6llx.svg',
    redIcon: '/parks-black.svg',
    icon: 'park-icon.svg'
  },
  {
    id: '2',
    label: 'Cruises',
    value: 'Cruises',
    grayIcon:
      'https://res.cloudinary.com/see-sight-tours/image/upload/v1713248189/icons-website/rqyxrqouu55yjhkwjy0m.svg',
    redIcon: '/cruises-black.svg',
    icon: 'cruises-icon.svg'
  },
  {
    id: '3',
    label: 'Historic Sites',
    value: 'Historic Sites',
    grayIcon:
      'https://res.cloudinary.com/see-sight-tours/image/upload/v1717576612/icons-website/z9bxzpendikt7iup0wyo.svg',
    redIcon: '/Historic-sites-black.svg',
    icon: 'amusement-park-icon.svg'
  },
  {
    id: '4',
    label: 'Restaurants',
    value: 'Restaurants',
    grayIcon:
      'https://res.cloudinary.com/see-sight-tours/image/upload/v1717565196/icons-website/wrqzjgrctc11uri6rqxd.svg',
    redIcon: '/restaurants-black.svg',
    icon: 'amusement-park-icon.svg'
  },
  {
    id: '5',
    label: 'Natural Wonders',
    value: 'Natural Wonders',
    grayIcon:
      'https://res.cloudinary.com/see-sight-tours/image/upload/v1717565197/icons-website/uszjzjjartplcqz5lo3w.svg',
    redIcon: '/natural-wonders-black.svg',
    icon: 'wildlife-icon.svg'
  },
  {
    id: '6',
    label: 'Markets',
    value: 'Markets',
    grayIcon:
      'https://res.cloudinary.com/see-sight-tours/image/upload/v1713248347/icons-website/mlxonzokkkpn0d7ly0kx.svg',
    redIcon: '/Markets-black.svg',
    icon: 'shop-icon.svg'
  },
  {
    id: '7',
    label: 'Lookouts',
    value: 'Lookout',
    grayIcon:
      'https://res.cloudinary.com/see-sight-tours/image/upload/v1713246697/icons-website/nejiwaxkawa3vcdzoafr.svg',
    redIcon: '/lookouts-black.svg',
    icon: 'binoculars-icon.svg'
  },
  {
    id: '10',
    label: 'Waterfront Attractions',
    value: 'Waterfront Attractions',
    grayIcon:
      'https://res.cloudinary.com/see-sight-tours/image/upload/v1717565199/icons-website/dzfvb8wnazki16l8zeqh.svg',
    redIcon: '/waterfront-attractions-black.svg',
    icon: 'amusement-park-icon.svg'
  },
  {
    id: '8',
    label: 'Museums',
    value: 'Museums',
    grayIcon:
      'https://res.cloudinary.com/see-sight-tours/image/upload/v1713248169/icons-website/f5ufcgeol9vzuw5nly2f.svg',
    redIcon: '/Museums-black.svg',
    icon: 'museum-icon.svg'
  },
  {
    id: '9',
    label: 'Bridges',
    value: 'Bridges',
    grayIcon: '/bridge-gray.svg',
    redIcon: '/bridge-black.svg',
    icon: 'amusement-park-icon.svg'
  },

  {
    id: '11',
    label: 'Squares',
    value: 'Squares',
    grayIcon:
      'https://res.cloudinary.com/see-sight-tours/image/upload/v1717565197/icons-website/xxja3hoo7f0mddcfn3w8.svg',
    redIcon: '/squares-black.svg',
    icon: 'event-icon.svg'
  },
  {
    id: '13',
    label: 'Towers',
    value: 'Towers',
    grayIcon:
      'https://res.cloudinary.com/see-sight-tours/image/upload/v1713248210/icons-website/btpjjrdbbntwverqufpt.svg',
    redIcon: '/Towers-black.svg',
    icon: 'landmark-icon.svg'
  },
  {
    id: '16',
    label: 'Government Buildings',
    value: 'Government Buildings',
    grayIcon:
      'https://res.cloudinary.com/see-sight-tours/image/upload/v1717576612/icons-website/skbxtgbh61jy8m1is7uw.svg',
    redIcon: '/government-buildings-black.svg',
    icon: 'amusement-park-icon.svg'
  },
  {
    id: '12',
    label: 'Monuments',
    value: 'Monuments',
    grayIcon: '/Monument-gray.svg',
    redIcon: '/Monument-black.svg',
    icon: 'theater-icon.svg'
  },

  {
    id: '15',
    label: 'Public Art',
    value: 'Public Art',
    grayIcon:
      'https://res.cloudinary.com/see-sight-tours/image/upload/v1717565200/icons-website/pvs374ze2dsioxpboytd.svg',
    redIcon: '/public-art-black.svg',
    icon: 'amusement-park-icon.svg'
  },

  {
    id: '17',
    label: 'Streets',
    value: 'Streets',
    grayIcon:
      'https://res.cloudinary.com/see-sight-tours/image/upload/v1717565199/icons-website/kafe6vgvtbdlirztrdlm.svg',
    redIcon: '/streets-black.svg',
    icon: 'amusement-park-icon.svg'
  },
  {
    id: '14',
    label: 'Observation Wheels',
    value: 'Observation Wheels',
    grayIcon:
      'https://res.cloudinary.com/see-sight-tours/image/upload/v1717565195/icons-website/zqh4j1bldpelrfxunhfk.svg',
    redIcon: '/observation-wheel-black.svg',
    icon: 'amusement-park-icon.svg'
  },

  {
    id: '18',
    label: 'Islands',
    value: 'Islands',
    grayIcon: '/Islands-gray.svg',
    redIcon: '/Islands-black.svg',
    icon: 'amusement-park-icon.svg'
  },
  {
    id: '19',
    label: 'Helicopters',
    value: 'Helicopters',
    grayIcon: '/helicopter-gray.svg',
    redIcon: '/helicopter-black.svg',
    icon: 'amusement-park-icon.svg'
  },
  {
    id: '20',
    label: 'Malls',
    value: 'Malls',
    grayIcon:
      'https://res.cloudinary.com/see-sight-tours/image/upload/v1717565195/icons-website/hcpwoursmrldeiz3nyrd.svg',
    redIcon: '/Shopping-Mall-black.svg',
    icon: 'amusement-park-icon.svg'
  },
  {
    id: '21',
    label: 'Arcade',
    value: 'Arcade',
    grayIcon: '/Arcade-gray.svg',
    redIcon: '/Arcade-black.svg',
    icon: 'arcade-icon.svg'
  },

  {
    id: '22',
    label: 'Shuttle Service',
    value: 'Shuttle Service',
    grayIcon:
      'https://res.cloudinary.com/see-sight-tours/image/upload/v1717576612/icons-website/aa1hyi7cmkwywcuodj3y.svg',
    redIcon: '/shuttle-service-black.svg',
    icon: 'amusement-park-icon.svg'
  }
];

export const DEFAULT_DROPDOWN_ATTRACTIONS = [
  {
    city: 'Niagara Falls',
    country: 'Canada',
    name: 'Niagara City Cruises',
    objectID: '32',
    rating: 4.8,
    slug: 'niagara-falls-tours-canada/Niagara-City-Cruises',
    _geoloc: { lat: 43.0889921, lng: -79.0733456 }
  },
  {
    city: 'Halifax',
    country: 'Canada',
    name: "Peggy's Cove Lighthouse",
    objectID: '75',
    rating: 4.8,
    slug: 'halifax-tours/peggys-cove-lighthouse',
    _geoloc: { lat: 44.4917514, lng: -63.91863859999999 }
  },
  {
    city: 'San Antonio',
    country: 'US',
    name: 'River Walk Cruises',
    objectID: '110',
    rating: 4.6,
    slug: 'san-antonio-tours/river-walk-cruises',
    _geoloc: { lat: 29.4241113, lng: -98.4889051 }
  }
];

export const DEFAULT_DROPDOWN_THINGSTODO = [
  {
    city: 'Niagara Falls',
    country: 'Canada',
    name: 'Things to do in Niagara Falls, Canada',
    id: '1',
    thingsToDo: 'niagara-falls-canada/things-to-do-in-niagara-falls-canada'
  },
  {
    city: 'Halifax',
    country: 'Canada',
    name: 'Things to do in Halifax',
    id: '2',
    thingsToDo: 'halifax/things-to-do-in-halifax'
  }
  // {
  //   city: 'San Antonio',
  //   country: 'US',
  //   name: 'Things to do in San Antonio',
  //   id: '3',
  //   slug: 'san-antonio/things-to-do-in-san-antonio'
  // }
];

export default function Home({ relatedUrls }: { relatedUrls: RelatedUrl[] }) {
  const updateQueryParams = useUpdateQueryParams();
  const [isScrolled, setIsScrolled] = useState(false);
  const [scrollOccurred, setScrollOccurred] = useState(false);

  const {
    searchQuery,
    setSearchQuery,
    showFullMap,
    selectedAttraction,
    setSelectedAttraction,
    setDropdownAttractions,
    dropdownAttractions,
    uniqueAttractionsThingsTodo,
    setUniqueAttractionsThingsTodo,
    landingPageCenterPoint,
    selectedCategory,
    setSelectedCategory,
    setLandingPageCenterPoint
  }: any = useSearchContext();
  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [attractions, setAttractions] = useState<AttractionCardType[]>([]);
  const [appendMode, setAppendMode] = useState(false);
  const [loadMoreAttractions, setLoadMoreAttractions] = useState(false);
  const [searchAttractionsLoader, setSearchedAttractionsLoader] =
    useState(false);
  const [isFirstLoad, setIsFirstLoad] = useState(true);
  const [radius, setRadius] = useState<number>(500);
  const [searchedFirstAttractionLoc, setSearchedFirstAttractionLoc] =
    useState();
  // const [showNewsletter, setShowNewsletter] = useState(false);

  const mapRef = useRef<L.Map>(null);

  const [isMobileScreen, setIsMobileScreen] = useState(
    typeof window !== 'undefined' && window.innerWidth <= 640
  );

  // Determine hits per page based on screen size
  const HITS_PER_PAGE = isMobileScreen ? 10 : 20;

  useEffect(() => {
    const checkScreenSize = () => {
      setIsMobileScreen(window.innerWidth <= 640);
    };

    window.addEventListener('resize', checkScreenSize);
    checkScreenSize();

    // Cleanup event listener on unmount
    return () => window.removeEventListener('resize', checkScreenSize);
  }, []);

  const getClassName = () => {
    if (!isScrolled && scrollOccurred) {
      return 'transition-all duration-300 animate-fade-down';
    } else if (isScrolled) {
      return 'transition-all duration-300 animate-fade-up hidden';
    } else {
      return '';
    }
  };

  useEffect(() => {
    setSelectedCategory('All Attractions');
    setCurrentPage(1);
  }, []);

  // useEffect(() => {
  //   if (!loadMoreAttractions) {
  //     const timer = setTimeout(() => {
  //       currentPage === totalPages && setShowNewsletter(true);
  //     }, 100);
  //     return () => clearTimeout(timer);
  //   }
  // }, [loadMoreAttractions]);

  useEffect(() => {
    setCurrentPage(1);
    setTimeout(() => {
      if (typeof window !== 'undefined') {
        if (window.scrollY > 20 && !showFullMap) {
          window.scrollTo({ top: 20, behavior: 'smooth' });
        }
      }
      // window.scrollTo({ top: 0, behavior: 'smooth' });
    }, 1000);
  }, [searchQuery, selectedCategory]);

  if (typeof window !== 'undefined') {
    window.onscroll = () => {
      const scrollPosition = window.scrollY;
      setIsScrolled(scrollPosition > 0);
      if (!scrollOccurred && scrollPosition > 0) {
        setScrollOccurred(true);
      }
    };
  }

  const index = searchClient.initIndex('attractions');

  function getUniqueAttractionsByCity(attractions: AttractionCardType[]) {
    const attractionsByCity = new Map<string, any[]>();

    // Group attractions by city
    for (const attraction of attractions) {
      if (attraction.city) {
        if (!attractionsByCity.has(attraction.city)) {
          attractionsByCity.set(attraction.city, []);
        }
        attractionsByCity.get(attraction.city)?.push(attraction);
      }
    }

    // Get unique attractions based on city
    const uniqueAttractions: any[] = [];

    attractionsByCity.forEach(attractionList => {
      uniqueAttractions.push(attractionList[0]);
    });

    return uniqueAttractions.slice(0, 2);
  }

  const fetchNearbyAttractions = (
    query: string,
    category: string,
    page: number,
    append: boolean,
    latlng: string
  ) => {
    setLoadMoreAttractions(true);
    let facetFilters: any = [];
    if (category && category == 'All Attractions') {
      facetFilters = [];
    } else {
      facetFilters = [`categories:${category}`];
    }
    index
      .search(query, {
        facetFilters: facetFilters,
        aroundLatLng: latlng ?? ``,
        aroundRadius: showFullMap ? Math.floor(radius * 1000) : 'all',
        page: showFullMap ? 0 : page - 1,
        hitsPerPage: showFullMap ? 1000 : HITS_PER_PAGE,
        restrictSearchableAttributes:
          selectedAttraction || searchQuery == ''
            ? undefined
            : ['name', 'city', 'country']
      })
      .then((response: any) => {
        const { hits, nbPages } = response;
        setTotalPages(nbPages);

        // Filter out attractions with empty coordinates or undefined lat/lng
        const filteredAttractions = hits.filter((attraction: any) => {
          const coords = attraction._geoloc;
          return (
            coords &&
            typeof coords.lat !== 'undefined' &&
            typeof coords.lng !== 'undefined'
          );
        });

        const modifiedAttractions = filteredAttractions.map(
          (attraction: any) => {
            const currentOpeningHours = getOpeningHoursForCurrentDay(
              attraction.openingHours
            );
            return {
              ...attraction,
              id: attraction.objectID,
              openingHours: currentOpeningHours,
              coordinates: {
                lat: attraction._geoloc.lat,
                lng: attraction._geoloc.lng
              }
            };
          }
        );

        if (append) {
          setAttractions(prevAttractions => [
            ...prevAttractions,
            ...modifiedAttractions
          ]);
        } else {
          setAttractions(modifiedAttractions);
        }

        // Get Unique Attractions to show Unique things todo in dropdown
        if (searchQuery || selectedCategory !== 'All Attractions') {
          if (page == 1) {
            setUniqueAttractionsThingsTodo(
              getUniqueAttractionsByCity(modifiedAttractions)
            );
            setDropdownAttractions(modifiedAttractions.slice(0, 3));
          }
        } else {
          setDropdownAttractions(DEFAULT_DROPDOWN_ATTRACTIONS);
        }
      })
      .catch((error: any) => {
        console.error('Error:', error);
      })
      .finally(() => {
        setLoadMoreAttractions(false);
      });
  };

  // Use coordinates from search results or default
  const determineLatLng = (hits: any[]): GeoLoc | null => {
    if (hits.length > 0 && hits[0]._geoloc) {
      const { lat, lng } = hits[0]._geoloc;
      return { lat, lng };
    }

    return null;
  };

  const fetchSearchedAttractions = (
    query: string,
    category: string,
    page: number,
    append: boolean
  ) => {
    append ? setLoadMoreAttractions(true) : setSearchedAttractionsLoader(true);
    let facetFilters: any = [];

    if (
      (category && category == 'All Attractions') ||
      category == 'All Tours'
    ) {
      facetFilters = [];
    } else {
      facetFilters = [`categories:${category}`];
    }
    index
      .search(query, {
        page: page - 1,
        hitsPerPage: HITS_PER_PAGE,
        facetFilters: facetFilters
      })
      .then((response: any) => {
        const { hits } = response;
        const nearbyCords = determineLatLng(hits);

        if (page == 1) {
          setSearchedFirstAttractionLoc(hits[0]);
        }
        if (hits.length > 0) {
          // conditions for setting aroundlatlng for fetching nearby attractions
          let aroundLatLng: string;

          if (query === '' && !showFullMap) {
            aroundLatLng = '';
          } else if (!selectedAttraction && query && !showFullMap) {
            if (page === 1) {
              aroundLatLng = `${hits[0]?._geoloc.lat},${hits[0]?._geoloc.lng}`;
            } else {
              aroundLatLng = `${
                (searchedFirstAttractionLoc as any)?._geoloc.lat
              },${(searchedFirstAttractionLoc as any)?._geoloc.lng}`;
            }
          } else {
            aroundLatLng = showFullMap
              ? `${landingPageCenterPoint.lat ?? nearbyCords?.lat},${
                  landingPageCenterPoint.lng ?? nearbyCords?.lng
                }`
              : `${nearbyCords?.lat},${nearbyCords?.lng}`;
          }
          fetchNearbyAttractions(query, category, page, append, aroundLatLng);
        } else {
          setAttractions(hits);
          setDropdownAttractions(hits);
        }
      })
      .catch((error: any) => {
        console.error('Error:', error);
      })
      .finally(() => {
        setLoadMoreAttractions(false);
        setSearchedAttractionsLoader(false);
      });
  };

  const debouncedSearchQuery = useDebounce(query => {
    fetchSearchedAttractions(query, selectedCategory, 1, false);
  }, 500);

  useEffect(() => {
    if (isFirstLoad) {
      setIsFirstLoad(false);
      return;
    }
    setAppendMode(false);
    if (
      !selectedAttraction ||
      (selectedAttraction && selectedAttraction.name !== searchQuery)
    ) {
      debouncedSearchQuery(searchQuery);
    }
  }, [searchQuery, radius, selectedCategory]);

  useEffect(() => {
    //When we search any attraction or click Load More on List View
    if (!selectedAttraction || searchQuery == '') {
      if (appendMode && !showFullMap) {
        fetchSearchedAttractions(
          searchQuery,
          selectedCategory,
          currentPage,
          true
        );
      } else {
        fetchSearchedAttractions(searchQuery, selectedCategory, 1, false);
      }
    }
  }, [appendMode, currentPage, showFullMap]);

  useEffect(() => {
    // When we select attraction from dropdown
    if (selectedAttraction !== undefined) {
      setSelectedCategory('All Attractions');
      if (!showFullMap) {
        //In List View we have two modes append and initial - based on selected Attraction
        if (appendMode) {
          fetchNearbyAttractions(
            '',
            'All Attractions',
            currentPage,
            true,
            `${selectedAttraction?._geoloc.lat},${selectedAttraction?._geoloc.lng}`
          );
        } else {
          if (selectedAttraction.name == searchQuery) {
            fetchNearbyAttractions(
              '',
              'All Attractions',
              1,
              false,
              `${selectedAttraction?._geoloc.lat},${selectedAttraction?._geoloc.lng}`
            );
          }
        }
      } else {
        setAppendMode(false);
        // In Map View we fetch attractions based on center point from map

        fetchNearbyAttractions(
          '',
          'All Attractions',
          currentPage,
          appendMode,
          `${landingPageCenterPoint.lat ?? selectedAttraction?._geoloc.lat},${
            landingPageCenterPoint.lng ?? selectedAttraction?._geoloc.lng
          }`
        );
      }
    }
  }, [selectedAttraction, appendMode, currentPage, radius, showFullMap]);

  const handleLoadMore = () => {
    setAppendMode(true);
    setCurrentPage(prevPage => prevPage + 1);
  };

  const handleCategoryClick = (category: string) => {
    if (category == 'All Attractions') {
      setSelectedAttraction(DEFAULT_DROPDOWN_ATTRACTIONS[0]);
      setLandingPageCenterPoint({ lat: null, lng: null });
    } else {
      setSelectedAttraction(undefined);
      setLandingPageCenterPoint({
        lat: searchedFirstAttractionLoc
          ? (searchedFirstAttractionLoc as SearchDropdownAttraction)._geoloc.lat
          : null,
        lng: searchedFirstAttractionLoc
          ? (searchedFirstAttractionLoc as SearchDropdownAttraction)._geoloc.lng
          : null
      });
    }

    setSelectedCategory(category);
    setCurrentPage(1);
    setAppendMode(false);
    setSearchQuery('');
    // Update the URL without the 'q' parameter and add the 'category' parameter
    updateQueryParams({ q: '', category: category }, '/things-to-do');
  };
  const [showBreadcrumbs, setShowBreadcrumbs] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowBreadcrumbs(true);
    }, 3000);

    return () => clearTimeout(timer);
  }, []);
  return (
    <>
      <Head>
        <meta
          name="p:domain_verify"
          content="923f0217e022a5ca05f663b3ffc170a5"
        />
        <meta
          name="og:title"
          content={`Top Things to Do : Discover Exciting Activities - See Sight Tours`}
        />
        <meta
          name="og:description"
          content={`Discover the top things to do in your city or travel destination! Explore popular attractions, hidden gems, local dining, and exciting activities.`}
        />
        <title>{`Top Things to Do : Discover Exciting Activities - See Sight Tours`}</title>
        <meta
          name="description"
          content={`Discover the top things to do in your city or travel destination! Explore popular attractions, hidden gems, local dining, and exciting activities.`}
        />
        <meta name="og:image" content="/vertical-seesight-logo.png" />
        <link
          rel="canonical"
          href={'https://www.seesight-tours.com/things-to-do'}
        />
      </Head>

      <main
        className="bg-white"
        style={{
          WebkitOverflowScrolling: 'touch'
        }}
      >
        {/* <section id="hero" className="relative">
          <PageHero
            title={'See Sight Tours Redefined'}
            snippet={'The future of travel is '}
            media="https://res.cloudinary.com/see-sight-tours/video/upload/q_60,w_1000,fl_progressive:steep//v1658237954/landing-page-hero_mu19mc.webm"
            video={true}
            landing
          />
          <CitiesModal />
        </section> */}

        <InstantSearch
          searchClient={searchClient}
          indexName="attractions"
          future={{ preserveSharedStateOnUnmount: true }}
        >
          <div
            className={`sticky top-[68px] lg:top-[80px] 3xl:top-[80px] z-[1000] bg-white`}
          >
            <div
              className={`relative z-[1000] w-[100%] bg-white ${
                isScrolled ? `px-5` : ` py-3`
              }
    ${getClassName()}`}
            >
              <CustomSearchBox
                width="w-[90%] mmsm:w-[65%] sm:w-[55%] md:w-[60%] mmd:w-[50%] lg:w-[40%] xl:w-[35%] 3xl:w-[30%]"
                height="h-[48px] md:h-[50px]"
                iconStyles={{
                  search: 'top-[4.5px] md:top-[7.5px] xl:top-[8px]',
                  clear: 'xxsm:top-[7px] md:top-[8px]'
                }}
                searchTerm={searchQuery}
                setSearchTerm={setSearchQuery}
                defaultAttractions={
                  searchQuery || selectedCategory !== 'All Attractions'
                    ? dropdownAttractions
                    : DEFAULT_DROPDOWN_ATTRACTIONS
                }
                defaultThingsTodo={
                  searchQuery || selectedCategory !== 'All Attractions'
                    ? uniqueAttractionsThingsTodo
                    : DEFAULT_DROPDOWN_THINGSTODO
                }
                setSelectedAttraction={setSelectedAttraction}
                pagetype={ELandingPage.THINGS_TO_DO_PAGE}
              />
            </div>

            <AttractionCategories
              categories={attractionCategories}
              pagetype={ELandingPage.THINGS_TO_DO_PAGE}
              showFullMap={showFullMap}
              selectedCategory={selectedCategory}
              handleCategoryClick={handleCategoryClick}
            />
          </div>

          <div>
            <LandingPage
              hits={attractions}
              totalPages={totalPages}
              hitsPerPage={HITS_PER_PAGE}
              setCurrentPage={setCurrentPage}
              currentPage={currentPage}
              handleLoadMore={handleLoadMore}
              isLoadingMoreAttractions={loadMoreAttractions}
              isLoadingSearchedAttractions={searchAttractionsLoader}
              radius={radius}
              setRadius={setRadius}
              uniqueAttractionsThingsTodo={uniqueAttractionsThingsTodo}
              mapRef={mapRef}
            />
          </div>
          {/* <Configure hitsPerPage={HITS_PER_PAGE} /> */}
        </InstantSearch>
        {/* {showNewsletter && (
          <div
            id="newsletter"
            ref={newsletterRef}
            className="relative z-[39] bg-white"
          >
            <Newsletter />
          </div>
        )} */}
        <div className="relative z-[39] py-6 sm:pt-4 bg-white">
          {!showFullMap && showBreadcrumbs && (
            <RelatedBreadcrumbLinks
              breadcrumbs={relatedUrls}
              pagetype={ELandingPage.TOURS_PAGE}
            />
          )}
        </div>
      </main>
      {!showFullMap && <Footer />}
    </>
  );
}
export async function getStaticProps() {
  const res = await fetch(
    `${process.env.NEXT_PUBLIC_FIREBASE_CONNECTION}/landing`
  );
  const data = await res.json();
  return {
    props: {
      relatedUrls: data.related_urls || []
    }
  };
}
