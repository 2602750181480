import React, {
  Dispatch,
  MouseEvent,
  SetStateAction,
  useEffect,
  useRef,
  useState
} from 'react';
import Image from 'next/image';
import { useAuthContext } from '../../../utils/context/authContext';
import { useRouter } from 'next/router';

interface ProfileDropdownProps {
  setMyToursModal: Dispatch<SetStateAction<boolean>>;
}

export const ProfileDropdown: React.FC<ProfileDropdownProps> = ({
  setMyToursModal
}) => {
  const { user, handleLogout }: any = useAuthContext();
  const router = useRouter();

  const profileMenuItems = [
    {
      label: 'My Bookings',
      iconUrl: '/booking-icon.svg',
      onClickHandler: () => {
        setMyToursModal(true);
        onMenuItemClick();
      }
    },
    // {
    //   label: 'Wishlist',
    //   iconUrl: '/black-wishlist-icon.svg',
    //   onClickHandler: () => {
    //     onMenuItemClick();
    //   }
    // },
    ...(user && user.emailVerified
      ? [
          {
            label: 'Account',
            iconUrl: '/black-accounts-icon.svg',
            onClickHandler: () => {
              router.push('/accounts');
              onMenuItemClick();
            }
          }
        ]
      : []),
    // {
    //   label: 'Delete Account',
    //   iconUrl: '/delete-icon.svg',
    //   onClickHandler: () => {
    //     // Add your logic for handling 'Delete Account' click
    //   }
    // },
    {
      label: user && user.emailVerified ? 'Log Out' : 'Sign In',
      iconUrl:
        user && user.emailVerified ? '/logout-icon.svg' : '/signin-icon.svg',
      onClickHandler: () => {
        user ? handleLogout() : router.push('/signin');
        onMenuItemClick();
      }
    }
  ];

  const dropdownRef = useRef<HTMLDivElement>(null);

  const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(false);

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const handleClickOutside = (event: any) => {
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target as Node)
    ) {
      setIsDropdownOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [isDropdownOpen]);

  const onMenuItemClick = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  return (
    <div className="relative group cursor-pointer" ref={dropdownRef}>
      <div
        className="flex flex-row-reverse rounded-full w-[90px] border border-[#E5E7EB] py-2 pr-2 pl-4 gap-[0.7rem] h-[47px]"
        onClick={toggleDropdown}
      >
        <Image
          src={
            user && user.photoURL ? user.photoURL : '/default-profile-icon.svg'
          }
          alt="profile-icon"
          width={100}
          height={100}
          quality={100}
          className="rounded-full h-[30px] w-[30px]"
        />
        <Image
          src={'/hamburger.svg'}
          alt="hamburger-icon"
          width={24}
          height={24}
        />
      </div>
      {isDropdownOpen && (
        <div
          className={`absolute py-7 px-7 right-0 mt-3 bg-white border border-gray-200 rounded-2xl shadow-lg sm:w-[200px] md:w-[210px] xl:w-[220px]`}
        >
          {/* {user && user.emailVerified && (
            <div className="flex flex-col gap-1">
              <h2 className="text-black13 text-base font-normal">
                {user?.displayName}
              </h2>
              <span className="text-xs font-normal text-black13 opacity-70">
                {user?.email}
              </span>
              <div className="h-[0.5px] mt-3 mb-[22px] opacity-[0.20] bg-gray-700"></div>
            </div>
          )} */}

          <div className="text-black13 opacity-60 text-[17px] font-semibold mb-[15px]">
            My profile
          </div>

          <ul>
            {profileMenuItems.map((menuItem, index) => (
              <div
                className={`flex flex-col gap-1 ${
                  index == profileMenuItems.length - 1 ? 'mb-0' : 'mb-[18px]'
                } ${user ? 'w-[85%]' : 'w-full'}`}
                key={index}
              >
                <li
                  className="place rounded-xl flex items-center"
                  onClick={menuItem.onClickHandler}
                >
                  <Image
                    src={menuItem.iconUrl}
                    alt={menuItem.label}
                    width={100}
                    height={100}
                    className={`${
                      menuItem.label == 'Log Out'
                        ? 'w-[20px]  h-[18px]'
                        : 'w-[20px]  h-[23px]'
                    } mr-2`}
                  />
                  <p className="text-center text-black13 sm:text-base font-normal">
                    {menuItem.label}
                  </p>
                </li>
                {/* <div className="h-[0.5px] opacity-[0.20] bg-gray-700"></div> */}
              </div>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};
