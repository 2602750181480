const Link = dynamic(() => import('next/link'));
import dynamic from 'next/dynamic';
import Image from 'next/image';
import SwiperCore, { Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { styled } from 'styled-components';
import { useCallback, useEffect, useState } from 'react';
import { Category, ELandingPage } from '../../../types/pages/landing-page';

// Install Swiper modules
SwiperCore.use([Navigation]);
interface IProps {
  categories: Category[];
  pagetype: ELandingPage;
  showFullMap?: boolean;
  selectedCategory: string;
  handleCategoryClick: (category: string) => void;
}

interface StyledSwiperProps {
  pagetype: ELandingPage;
  isfirstslide: string;
}

const StyledSwiper = styled(Swiper)<StyledSwiperProps>`
  width: auto !important;

  .swiper {
    width: auto !important;
  }
  .swiper-slide {
    width: auto !important;
    display: flex;
    align-items: center;
  }
  .swiper-wrapper {
    display: flex !important;
    @media (min-width: 1024px) {
      justify-content: ${props =>
        props.pagetype == ELandingPage.THINGS_TO_DO_PAGE
          ? ``
          : 'center'} !important;
    }
    @media (min-width: 2400px) {
      justify-content: center !important;
    }

    gap: ${props =>
      props.pagetype == ELandingPage.THINGS_TO_DO_PAGE
        ? `23px`
        : '22px'} !important;
    @media (min-width: 375px) {
      gap: ${props =>
        props.pagetype == ELandingPage.THINGS_TO_DO_PAGE
          ? `35px`
          : '33px'} !important;
    }
    @media (min-width: 390px) {
      gap: ${props =>
        props.pagetype == ELandingPage.THINGS_TO_DO_PAGE
          ? `39px`
          : '38px'} !important;
    }
    @media (min-width: 400px) {
      gap: ${props =>
        props.pagetype == ELandingPage.THINGS_TO_DO_PAGE
          ? `36px`
          : '37px'} !important;
    }
    @media (min-width: 420px) {
      gap: ${props =>
        props.pagetype == ELandingPage.THINGS_TO_DO_PAGE
          ? `39px`
          : '40px'} !important;
    }
    @media (min-width: 640px) {
      gap: ${props =>
        props.pagetype == ELandingPage.THINGS_TO_DO_PAGE
          ? `44px`
          : '43px'} !important;
    }
    @media (min-width: 1024px) {
      gap: ${props =>
        props.pagetype == ELandingPage.THINGS_TO_DO_PAGE
          ? `48px`
          : '42px'} !important;
    }

    @media (min-width: 1280px) {
      gap: ${props =>
        props.pagetype == ELandingPage.THINGS_TO_DO_PAGE
          ? `41px`
          : '53px'} !important;
    }
    @media (min-width: 1440px) {
      gap: ${props =>
        props.pagetype == ELandingPage.THINGS_TO_DO_PAGE
          ? `42px`
          : '53px'} !important;
    }

    @media (min-width: 1536px) {
      gap: ${props =>
        props.pagetype == ELandingPage.THINGS_TO_DO_PAGE
          ? `40px`
          : '65px'} !important;
    }
    @media (min-width: 1920px) {
      gap: ${props =>
        props.pagetype == ELandingPage.THINGS_TO_DO_PAGE
          ? `39px`
          : '65px'} !important;
    }

    margin-left: ${props => (props.isfirstslide == 'true' ? `50px` : '0px')};
    @media (min-width: 1024px) {
      margin-left: ${props =>
        props.isfirstslide == 'true' &&
        props.pagetype == ELandingPage.THINGS_TO_DO_PAGE
          ? `50px`
          : '0px'};
    }
  }
`;

const AttractionCategories = ({
  categories,
  pagetype,
  showFullMap,
  selectedCategory,
  handleCategoryClick
}: IProps) => {
  const [isAtStart, setIsAtStart] = useState(true);
  const [isAtEnd, setIsAtEnd] = useState(false);

  const handleSlideChange = (swiper: SwiperCore) => {
    setIsAtStart(swiper.isBeginning);
    setIsAtEnd(swiper.isEnd);
  };

  const [showArrows, setShowArrows] = useState(false);

  // Function to check arrow visibility
  const checkArrowVisibility = useCallback(() => {
    const isSmallerScreen = window.innerWidth < 1024;
    const isToursPage = pagetype === ELandingPage.TOURS_PAGE;

    setShowArrows(
      (isSmallerScreen && isToursPage) ||
        pagetype === ELandingPage.THINGS_TO_DO_PAGE
    );
  }, [pagetype]);

  useEffect(() => {
    checkArrowVisibility();

    window.addEventListener('resize', checkArrowVisibility);

    return () => {
      window.removeEventListener('resize', checkArrowVisibility);
    };
  }, [checkArrowVisibility]);

  return (
    <section
      id="categories"
      className={`py-2 px-3 bg-white shadow-md ${
        showFullMap ? `mb-0` : `mb-8`
      } flex items-center relative`}
    >
      <div className="w-full md:w-[85%] xl:w-[90%] 2xl:w-[70%] 3xl:w-[85%] 4xl:w-[80%] mx-auto">
        <StyledSwiper
          pagetype={pagetype}
          isfirstslide={isAtStart.toString()}
          onSlideChange={handleSlideChange}
          className={`${pagetype == ELandingPage.TOURS_PAGE && `h-[70px]`}`}
          slidesPerView={10}
          grabCursor
          breakpoints={{
            320: {
              slidesPerView: pagetype == ELandingPage.THINGS_TO_DO_PAGE ? 3 : 3
            },
            360: {
              slidesPerView:
                pagetype == ELandingPage.THINGS_TO_DO_PAGE ? 3.4 : 3.3
            },
            375: {
              slidesPerView:
                pagetype == ELandingPage.THINGS_TO_DO_PAGE ? 3.2 : 3.3
            },

            412: {
              slidesPerView:
                pagetype == ELandingPage.THINGS_TO_DO_PAGE ? 3.5 : 3.5
            },

            640: {
              slidesPerView:
                pagetype == ELandingPage.THINGS_TO_DO_PAGE ? 5.1 : 5
            },
            768: {
              slidesPerView: pagetype == ELandingPage.THINGS_TO_DO_PAGE ? 5 : 5
            },
            1024: {
              slidesPerView: 6.4
            },
            1280: {
              slidesPerView: 9
            },
            1440: {
              slidesPerView: 10
            },
            1536: {
              slidesPerView: 8.5
            },
            1800: {
              slidesPerView: 12
            },
            1920: {
              slidesPerView: 13
            },
            2200: {
              slidesPerView: 14
            }
          }}
          navigation={{
            nextEl: '.swiper-container-next',
            prevEl: '.swiper-container-prev'
          }}
        >
          {categories.map((dt, index) => (
            <SwiperSlide
              key={index}
              onClick={() => handleCategoryClick(dt.value)}
              onTouchStart={() => handleCategoryClick(dt.value)}
            >
              <div key={dt.id} className="w-full">
                <div
                  className={`flex flex-col gap-[5px] justify-between items-center h-full cursor-pointer w-full ${
                    index == categories.length - 1 && `xsm:mr-[-20px] msm:mr-0`
                  } `}
                >
                  {(dt.grayIcon || dt.redIcon) && (
                    <Image
                      src={
                        selectedCategory === dt.value
                          ? `${dt.redIcon}`
                          : `${dt.grayIcon}`
                      }
                      width={20}
                      loading="eager"
                      height={20}
                      alt="category icon"
                      priority={true}
                      className="w-7 h-6 lg:w-8 3xl:w-8 md:h-8 mx-4"
                    />
                  )}
                  <p
                    className={`flex-none whitespace-nowrap text-[12px] md:text-[14px] xl:text-[14px] ${
                      selectedCategory === dt.value
                        ? `text-[#111623] font-semibold`
                        : `text-black5`
                    }  text-center font-medium`}
                  >
                    {dt.label}
                  </p>
                </div>
              </div>
            </SwiperSlide>
          ))}

          <div
            className={`w-[2.5rem] ${showArrows ? 'flex' : 'hidden'} ${
              pagetype == ELandingPage.TOURS_PAGE
                ? `h-[4.15rem]`
                : `h-[4.25rem]`
            } bg-white justify-start  absolute z-20 cursor-pointer swiper-container-prev items-center left-0 ${
              pagetype == ELandingPage.THINGS_TO_DO_PAGE
                ? `top-[-14px]  md:top-0`
                : `top-0`
            }`}
          >
            <Image
              src={
                isAtStart
                  ? '/gray-circled-left-arrow.svg'
                  : '/blue-circled-left-arrow.svg'
              }
              alt="left-icon"
              width={50}
              height={50}
              className="w-8 h-8 md:w-12 md:h-12"
              priority={true}
            />
          </div>
          <div
            className={`w-[2.5rem] ${showArrows ? 'flex' : 'hidden'}  ${
              pagetype == ELandingPage.TOURS_PAGE
                ? `h-[4.15rem]`
                : `h-[4.25rem]`
            } bg-white !justify-end  absolute z-20 cursor-pointer swiper-container-next items-center right-0 ${
              pagetype == ELandingPage.THINGS_TO_DO_PAGE
                ? ` top-[-14px] md:top-0`
                : `top-0`
            }`}
          >
            <Image
              src={
                isAtEnd
                  ? '/gray-circled-right-arrow.svg'
                  : '/blue-circled-right-arrow.svg'
              }
              alt="right-icon"
              width={50}
              height={50}
              className="w-8 h-8 md:w-12 md:h-12"
              priority={true}
            />
          </div>
        </StyledSwiper>
      </div>
    </section>
  );
};

export default AttractionCategories;
