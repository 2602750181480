const organizationSchema = {
  '@context': 'https://schema.org',
  '@type': 'Organization',
  name: 'See Sight Tours',
  email: 'info@seesight-tours.com',
  telePhone: '+1-289-271-9767',
  url: 'https://www.seesight-tours.com/',
  Image: '/vertical-seesight-logo.png',
  Logo: '/vertical-seesight-logo.png',
  address: {
    '@type': 'PostalAddress',
    streetAddress: '5779 Desson Avenue Niagara Falls',
    addressLocality: 'Ontario',
    addressRegion: 'Canada',
    postalCode: 'L2G 3T5'
  },
  sameAs: [
    'https://www.instagram.com/seesighttours/',
    'https://www.facebook.com/NiagaraFallsTours',
    'https://x.com/tripshepherdINC',
    'https://www.youtube.com/user/SeeSightTours'
  ],
  aggregateRating: {
    '@type': 'AggregateRating',
    ratingValue: '4.9',
    ratingCount: '5300',
    bestRating: '5',
    worstRating: '1'
  }
};
export const WebsiteSchema = {
  '@context': 'https://schema.org',
  '@type': 'WebSite',
  name: 'See Sight Tours',
  alternateName: 'See Sight Tours',
  url: 'https://www.seesight-tours.com/'
};
export default organizationSchema;
