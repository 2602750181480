import React, { useState, useEffect, useRef } from 'react';
import Image from 'next/image';
import { useRouter } from 'next/router';
import { useMediaQuery } from '../../hooks/mediaQuery';

interface ShareButtonProps {
  isOpen: boolean;
  onOpen: () => void;
  onClose: () => void;
  slug: string;
}

const ShareButton: React.FC<ShareButtonProps> = ({
  isOpen,
  onOpen,
  onClose,
  slug
}) => {
  const router = useRouter();
  const src = router.pathname.includes('/tours/')
    ? '/share-white-icon.svg'
    : '/share-button.svg';
  const menuRef = useRef<HTMLDivElement>(null);
  const mediaQuery = useMediaQuery(768);
  const [linkCopied, setLinkCopied] = useState(false);

  const handleButtonClick = (event: any) => {
    event.stopPropagation();

    if (mediaQuery) {
      navigator
        .share({
          title: 'Join me on a See Sight Tours adventure!',
          text: `I found this experience on See Sight Tours, want to go?`,
          url: `https://www.seesight-tours.com/tours/${slug}`
        })
        .catch(err => console.error('Error sharing:', err));
    } else {
      if (isOpen) {
        onClose();
      } else {
        onOpen();
      }
    }
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
        onClose();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [onClose]);

  const handleEmailClick = (event: any) => {
    event.stopPropagation();
    onClose();
    const mailtoLink = `mailto:?subject=Join me on a See Sight Tours adventure!&body=Check out this tour: https://www.seesight-tours.com/tours/${slug}`;
    window.location.href = mailtoLink;
  };

  const handleCopyLinkClick = (event: any) => {
    event.stopPropagation();
    const link = `https://www.seesight-tours.com/tours/${slug}`;
    navigator.clipboard.writeText(link).then(
      () => {
        setLinkCopied(true);
        setTimeout(() => {
          setLinkCopied(false);
          onClose();
        }, 2000);
      },
      err => {
        console.error('Could not copy text: ', err);
      }
    );
  };

  return (
    <div className="relative inline-block w-[max-content]" ref={menuRef}>
      <button
        onClick={handleButtonClick}
        className="share-button bg-transparent border-none cursor-pointer rounded-full hover:bg-[#1A90D0] group"
      >
        <Image
          src={src}
          width={32}
          height={32}
          quality={100}
          alt="Share Button"
          className={`w-[28px] h-[28px] ${
            router.pathname.includes('/tours/')
              ? 'md:h-[35px] md:w-[35px]'
              : 'md:h-[32px] md:w-[32px]'
          }`}
        />
      </button>

      {isOpen && !mediaQuery && (
        <div className="absolute right-0 mt-2 w-32 bg-white shadow-lg rounded-lg z-10">
          <div className="absolute top-[-8px] right-3.5 w-0 h-0 border-l-[10px] border-l-transparent border-r-8 border-r-transparent border-b-8 border-b-white"></div>

          <div
            className="p-2 text-[14px] text-[#777777] cursor-pointer border-b hover:rounded-t-lg border-gray-200 hover:bg-grayEF"
            onClick={handleEmailClick}
          >
            <div className="flex gap-3">
              <Image
                src={'/email-icon.svg'}
                width={16}
                height={16}
                alt="Email Icon"
              />
              <span>Email</span>
            </div>
          </div>

          <div
            className="p-2 text-[14px] text-[#777777] cursor-pointer hover:rounded-b-lg hover:bg-grayEF"
            onClick={handleCopyLinkClick}
          >
            <div className="flex gap-3">
              <Image
                src={'/copy-icon.svg'}
                width={16}
                height={16}
                alt="Copy Link Icon"
              />
              <span>{linkCopied ? 'Link Copied' : 'Copy link'}</span>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ShareButton;
