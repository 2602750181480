import React from 'react';

interface Props {
  color: 'red' | 'gray';
}

const ToursIcon: React.FC<Props> = ({ color }) => {
  const iconColor = color === 'red' ? '#1A90D0' : '#717171';

  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.2502 18.468L14.2691 14.4869M14.2691 14.4869C14.9501 13.8059 15.4903 12.9975 15.8588 12.1077C16.2274 11.218 16.417 10.2643 16.417 9.3013C16.417 8.33824 16.2274 7.38462 15.8588 6.49488C15.4903 5.60513 14.9501 4.79669 14.2691 4.11571C13.5881 3.43473 12.7797 2.89455 11.8899 2.526C11.0002 2.15746 10.0466 1.96777 9.08352 1.96777C8.12047 1.96777 7.16685 2.15746 6.2771 2.526C5.38736 2.89455 4.57892 3.43473 3.89794 4.11571C2.52264 5.49101 1.75 7.35632 1.75 9.3013C1.75 11.2463 2.52264 13.1116 3.89794 14.4869C5.27324 15.8622 7.13855 16.6348 9.08352 16.6348C11.0285 16.6348 12.8938 15.8622 14.2691 14.4869Z"
        stroke={iconColor}
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default ToursIcon;
