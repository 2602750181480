import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import 'react-toastify/dist/ReactToastify.css';
import { useMutation } from '@apollo/client';
import SUBSCRIBE_TO_NEWSLETTER from '../../api/newsletterSubscriptionMutation';
import { extractUserName } from '../../utils/getUserName';
import { useAuthContext } from '../../utils/context/authContext';
import { useRouter } from 'next/router';
import Image from 'next/image';
import { SocialMediaIcons } from '../../shared-components/SocialMediaIcons';

const Newsletter = ({}) => {
  const { user }: any = useAuthContext();
  const [email, setEmail] = useState('');
  const [subscribeToNewsletter] = useMutation(SUBSCRIBE_TO_NEWSLETTER);
  useEffect(() => {
    setEmail(user !== null && user.emailVerified ? user.email : '');
  }, [user]);

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    const username = extractUserName(email);

    event.preventDefault();
    event.stopPropagation();
    try {
      const { data } = await subscribeToNewsletter({
        variables: {
          email: email,
          status: 'Subscribed'
        }
      });
      if (data.insert_boatnew_news_letter_subscribers.affected_rows > 0) {
        const emailData = {
          userName: username,
          email: email
        };
        await fetch('/api/subscription_mail', {
          method: 'POST',
          body: JSON.stringify(emailData)
        });
        toast.success('Successfully subscribed to our Newsletters!');
        setEmail('');
      } else {
        toast.error('Subscription failed. Please try again later.');
        setEmail('');
      }
    } catch (error) {
      if (
        error instanceof Error &&
        error.toString().includes('Uniqueness violation')
      ) {
        toast.error(
          'This email is already subscribed. Please use a different email.'
        );
      } else {
        toast.error('An error occurred. Please try again later.');
      }
    }
  };

  const NewsletterHeading = styled.h2`
    font-weight: 600;
    text-align: center;
    color: #333333;
  `;
  const SeparatorLine = styled.div`
    height: 1px;
    background: rgba(5, 7, 60, 0.6);
    border: 1px rgba(19, 19, 19, 0.6);
  `;
  const Newsletter = styled.p`
    font-weight: 400;
    text-align: center;
  `;
  const router = useRouter();
  return (
    <>
      <section
        id="newsletter"
        className={`sm:pt-16 sm:pb-32 pb-5 pt-14 flex justify-center items-center relative z-10`}
      >
        <div className="flex flex-col gap-3 sm:gap-4 w-[80%] md:w-[80%] lg:w-[80%] xl:w-[55%] 3xl:w-[45%]">
          {/* <div
            className={`${
              router.route === '/things-to-do' ? 'block' : 'hidden'
            }`}
          >
            <div className="flex justify-center">
              <Image
                src="/logo.svg"
                width={110}
                height={110}
                alt="calendar"
                className="xl:h-[110px] xl:w-[110px] md:h-[110px] md:w-[90px] h-[110px] w-[90px] "
              />
            </div>
            <div className="text-[20px] xsm:text-[22px] sm:text-[32px] md:text-[50px] xl:text-[70px] flex justify-center text-[#222] tracking-[10px] ">
              COMING SOON
            </div>
          </div> */}
          <div className={`flex flex-col gap-3`}>
            <p className="text-center text-black13 text-opacity-60 text-base sm:text-lg font-normal leading-snug">
              Newsletter
            </p>
            <NewsletterHeading className="text-center text-black13 text-base sm:text-2xl font-semibold sm:leading-[40px]">
              Subscribe to our newsletter!
            </NewsletterHeading>
            <SeparatorLine className="mx-[1rem] sm:mx-[5rem] lg:mx-[10rem]" />
          </div>
          <div className="flex justify-center">
            <Newsletter className="text-sm  sm:text-base md:text-lg text-black13 text-opacity-60 w-[528px]">
              Signup to our newsletter and we will make sure to let you know
              whenever we have news!
            </Newsletter>
          </div>

          <form onSubmit={handleSubmit}>
            <div className="flex justify-center relative sm:mx-20 lg:mx-28 2xl:mx-[6rem] 4xl:mx-[14rem]">
              <input
                className="rounded-full text-start pl-[5%] md:pl-[3%] text-black13 xxsm:text-[10px] mmsm:text-base msm:text-sm md:text-lg font-medium leading-[18px] p-2 w-[100%] border   border-gray-400 focus:outline-none h-[50px] sm:h-[55px] lg:h-[65px]"
                placeholder="Enter your email"
                name="email"
                required
                type="email"
                onChange={e => setEmail(e.target.value)}
                maxLength={50}
                minLength={2}
                value={email}
              />
              <button
                className={`xxsm:text-xs mmsm:text-sm lg:text-base w-fit leading-[0.5rem] cursor-pointer absolute xxsm:top-[0.2rem] mmsm:top-[0.1rem] sm:top-[0.45rem] xxsm:right-[0.25rem] mmsm:right-0 sm:right-2   bg-primary border-[#1A90D0] rounded-full font-medium border-2  sm:leading-none text-white  hover:bg-blue-400 hover:shadow-lg focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-500 active:shadow-lg transition duration-150 ease-in-out p-3`}
                data-mdb-ripple="true"
                data-mdb-ripple-color="light"
              >
                Subscribe
              </button>
            </div>
          </form>
        </div>
      </section>
    </>
  );
};

export default Newsletter;
