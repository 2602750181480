import Image from 'next/image';
import React, { useState, useCallback, useEffect, useContext } from 'react';
import Modal from '../../components/MyTours/BookingSearchModal';
import dynamic from 'next/dynamic';
import { PaxContext } from '../../utils/context/checkoutContext';
import { ProfileDropdown } from './ProfileDropdown/ProfileDropdown';
import { useRouter } from 'next/router';
import { useSearchContext } from '../../utils/context/searchContext';
import { InstantSearch } from 'react-instantsearch-core';
import algoliasearch from 'algoliasearch/lite';
import { CustomSearchBox } from '../../shared-components/SearchBar/SearchBar';
import {
  DEFAULT_DROPDOWN_ATTRACTIONS,
  DEFAULT_DROPDOWN_THINGSTODO
} from '../../pages/things-to-do';
import { DEFAULT_DROPDOWN_CITIES } from '../../pages';
import { ELandingPage } from '../../types/pages/landing-page';
import { BottomBar } from './BottomBar.tsx/BottomBar';

const Link = dynamic(() => import('next/link'));

export const useMediaQuery = (width: number) => {
  const [targetReached, setTargetReached] = useState(false);

  const updateTarget = useCallback((e: { matches: any }) => {
    if (e.matches) {
      setTargetReached(true);
    } else {
      setTargetReached(false);
    }
  }, []);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const media = window.matchMedia(`(max-width:${width}px)`);
      media.addEventListener('change', updateTarget);

      if (media.matches) {
        setTargetReached(true);
      }

      return () => media.removeEventListener('change', updateTarget);
    }
  }, []);

  return targetReached;
};

const searchClient = algoliasearch(
  process.env.NEXT_PUBLIC_ALGOLIA_APP_ID || '',
  process.env.NEXT_PUBLIC_ALGOLIA_API_KEY || ''
);

const NavBar = () => {
  const [isOpen, setOpen] = useState<boolean>(false);
  const [myToursModal, setMyToursModal] = useState(false);
  const router = useRouter();
  const [isScrolled, setIsScrolled] = useState<boolean>(false);
  const {
    searchQuery,
    setSearchQuery,
    setSelectedAttraction,
    dropdownAttractions,
    uniqueAttractionsThingsTodo,
    setSelectedCategory,
    dropdownTours,
    dropdownCities,
    setSelectedTourCity,
    setShowFullMap,
    showFullMap
  }: any = useSearchContext();
  const [isSmallScreen, setIsSmallScreen] = useState(false);

  useEffect(() => {
    const checkScreenSize = () => {
      setIsSmallScreen(window.innerWidth < 640);
    };
    window.addEventListener('resize', checkScreenSize);
    checkScreenSize();
    return () => window.removeEventListener('resize', checkScreenSize);
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      setIsScrolled(scrollPosition > 0);
    };

    if (typeof window !== 'undefined') {
      window.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (typeof window !== 'undefined') {
        window.removeEventListener('scroll', handleScroll);
      }
    };
  }, []);

  //@ts-ignore
  const { blogCity, updateBlogCity } = useContext(PaxContext);

  return (
    <>
      <header className="px-[6%] xl:px-[5%] 2xl:px-[5.5%] sticky top-0 z-[1100] h-[75px] lg:h-[80px] 3xl:h-[80px] bg-[#fff] max-h-[70px] 3xl:max-h-[80px] lg:max-h-[80px] flex items-center">
        <div className="pt-2 smd:pt-5 mx-auto w-full ">
          <div className="flex justify-between items-center gap-3 2xl:gap-12">
            <div
              className={`logo ${
                isSmallScreen &&
                isScrolled &&
                (router.pathname === '/' || router.pathname === '/things-to-do')
                  ? 'hidden'
                  : 'flex'
              }   ${
                router.route == '/profile'
                  ? `justify-center w-full`
                  : `justify-start`
              }`}
            >
              <Link href="/">
                <Image
                  alt="See Sight Tours logo"
                  src={'/navbar-seesight-logo.png'}
                  width={400}
                  height={200}
                  className="max-h-[150px] w-48 sm:w-[18rem] lg:w-[26rem]"
                  priority
                  placeholder="blur"
                  blurDataURL="/placeholder.webp"
                />
              </Link>
            </div>

            {isScrolled &&
            (router.pathname === '/' || router.pathname === '/things-to-do') ? (
              <InstantSearch
                searchClient={searchClient}
                indexName="attractions"
                future={{ preserveSharedStateOnUnmount: true }}
              >
                <div className="sticky pt-5 w-[100%] animate-fade-up">
                  <CustomSearchBox
                    width={`w-[100%] sm:w-[70%] md:w-[65%] 3xl:w-[50%]`}
                    height="h-[48px] md:h-[48px] xl:h-[50px]"
                    iconStyles={{
                      search: 'top-[4.5px] md:top-[5.5px] xl:top-[8px]',
                      clear: 'top-[7px] xl:top-[8px]'
                    }}
                    searchTerm={searchQuery}
                    setSearchTerm={setSearchQuery}
                    defaultAttractions={
                      router.pathname === '/things-to-do'
                        ? searchQuery
                          ? dropdownAttractions
                          : DEFAULT_DROPDOWN_ATTRACTIONS
                        : undefined
                    }
                    defaultThingsTodo={
                      router.pathname === '/things-to-do'
                        ? searchQuery
                          ? uniqueAttractionsThingsTodo
                          : DEFAULT_DROPDOWN_THINGSTODO
                        : undefined
                    }
                    defaultCities={
                      searchQuery ? dropdownCities : DEFAULT_DROPDOWN_CITIES
                    }
                    defaultTours={dropdownTours}
                    setSelectedAttraction={setSelectedAttraction}
                    setSelectedCity={setSelectedTourCity}
                    pagetype={
                      router.pathname === '/things-to-do'
                        ? ELandingPage.THINGS_TO_DO_PAGE
                        : ELandingPage.TOURS_PAGE
                    }
                  />
                </div>
              </InstantSearch>
            ) : (
              <div className="hidden sm:flex Navlinks w-full text-[#060606] justify-center  text-base xl:text-lg xxsm:gap-3 lg:gap-4 2xl:gap-5 3xl:gap-8">
                <Link
                  href={blogCity ? `/${blogCity}` : '/'}
                  className={`text-[15px] xl:text-base
                  ${
                    // router.pathname.split('/')[1] === 'tours' ||
                    router.pathname == '/'
                      ? 'px-1 text-primary underline-active'
                      : 'hover:text-primary px-1'
                  }
                `}
                  onClick={() => {
                    updateBlogCity(undefined);
                    setSelectedCategory('All Tours');
                    setSearchQuery('');
                  }}
                >
                  Tours
                </Link>
                <Link
                  href={'/things-to-do'}
                  className={`text-[15px] xl:text-base
                  ${
                    router.pathname === '/things-to-do'
                      ? 'px-1 text-primary underline-active'
                      : 'hover:text-primary px-1'
                  }
                `}
                  onClick={() => {
                    setSelectedCategory('All Attractions');
                    setSearchQuery('');
                    setShowFullMap(false);
                  }}
                >
                  Things To do
                </Link>
                <Link
                  href="/cities"
                  className={`text-[15px] xl:text-base sm:hidden md:flex
                      ${
                        router.pathname === '/cities'
                          ? 'px-1 text-primary underline-active'
                          : 'hover:text-primary px-1'
                      }`}
                >
                  Our Cities
                </Link>
                <Link
                  href="/reviews"
                  className={`text-[15px] xl:text-base
                    ${
                      router.pathname === '/reviews'
                        ? 'px-1 text-primary underline-active'
                        : 'hover:text-primary px-1'
                    }`}
                >
                  Reviews
                </Link>
                <Link
                  href="/about"
                  className={`text-[15px] xl:text-base sm:hidden xl:flex
                    ${
                      router.pathname === '/about'
                        ? 'px-1 text-primary underline-active'
                        : 'hover:text-primary px-1'
                    }`}
                >
                  About Us
                </Link>
              </div>
            )}
            <div className="hidden sm:flex gap-6 items-center justify-end">
              <ProfileDropdown setMyToursModal={setMyToursModal} />
            </div>
          </div>
        </div>

        <Modal open={myToursModal} setOpenModal={setMyToursModal} />
      </header>
      {isSmallScreen && router.route !== '/accounts' && !showFullMap && (
        <BottomBar />
      )}
    </>
  );
};

export default NavBar;
