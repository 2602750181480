function replaceText(inputString) {
  // Replace "https://www.seesight-tours.com" with "https://www.tripshepherd.com"
  const replacedString1 = inputString?.replace(
    /https:\/\/www\.seesight-tours\.com/g,
    'https://www.tripshepherd.com'
  );

  // Replace "See Sight Tours", "seesight", and "see sight" with  "Trip Shepperd"
  const replacedString2 = replacedString1?.replace(
    /See Sight Tours|See Sight Tour|Seesight Tours|Trip Shepperd/gi,
    'Tripshepherd'
  );

  return replacedString2;
}
export function replaceTripShepherd(inputString) {
  // Replace "https://www.seesight-tours.com" with "http://trip-shepperd.com"
  // const replacedString1 = inputString.replace(
  //   /https:\/\/www\.seesight-tours\.com/g,
  //   'http://trip-shepperd.com'
  // );

  // Replace "See Sight Tours", "seesight", and "see sight" with  "Trip Shepperd"
  const replacedString2 = inputString?.replace(
    /Tripshepherd/gi,
    'See Sight Tours'
  );

  return replacedString2;
}

export function replaceCanonical(inputString) {
  // Replace "https://www.seesight-tours.com" with "http://trip-shepperd.com"
  if (!inputString) {
    return;
  }
  const replacedString1 = inputString?.replace(
    /https:\/\/www\.seesight-tours\.com/g,
    'https://www.trip-shepperd.com'
  );

  return replacedString1;
}
export function replaceTripshepherdWithSeeSight(input) {
  // Check if the input is null or undefined
  if (input == null) return input;

  // Regex to detect any Firebase storage URL referencing tripshepherd-prod
  // (e.g. https://firebasestorage.googleapis.com/v0/b/tripshepherd-prod.firebasestorage.app/o/... )
  // We want to skip these entirely, leaving them untouched.
  const firebaseTripshepherdProdRegex = new RegExp(
    'https?:\\/\\/firebasestorage\\.googleapis\\.com\\/v0\\/b\\/tripshepherd-prod\\.firebasestorage\\.app\\/o\\/[^\\s]*',
    'gi'
  );

  // Regex to detect general URLs anywhere else
  const urlRegex = /(https?:\/\/[^\s]+)/g;

  if (typeof input === 'string') {
    // Step 1: Protect any link containing "tripshepherd-prod" by replacing it with a placeholder
    const placeholders = [];
    let protectedString = input.replace(
      firebaseTripshepherdProdRegex,
      match => {
        placeholders.push(match);
        return '__FIREBASE_TS_PROD__'; // unique placeholder
      }
    );

    // Step 2: Perform normal replacements on everything else
    protectedString = protectedString
      .replace(urlRegex, url => {
        // For any URL that wasn't matched above, replace "tripshepherd" with "seesight-tours"
        return url.replace(/tripshepherd/gi, 'seesight-tours');
      })
      .replace(/Trip Shepperd/g, 'See Sight Tours') // Replace text
      .replace(/Tripshepherd/g, 'See Sight Tours') // Replace text
      .replace(/TripShepherd/g, 'seesight-tours') // Replace text
      .replace(/tripshepherd/g, 'seesight-tours') // Replace text
      .replace(/Formerly See Sight Tours/g, 'Powered By Tripshepherd'); // Replace text

    // Step 3: Restore the untouched "tripshepherd-prod" Firebase links
    let placeholderIndex = 0;
    protectedString = protectedString.replace(/__FIREBASE_TS_PROD__/g, () => {
      return placeholders[placeholderIndex++];
    });

    return protectedString;
  }

  // If it's an array, apply the function recursively
  if (Array.isArray(input)) {
    return input.map(item => replaceTripshepherdWithSeeSight(item));
  }

  // If it's an object, apply the function recursively to each field
  if (typeof input === 'object') {
    const newObj = {};
    for (const key in input) {
      if (Object.prototype.hasOwnProperty.call(input, key)) {
        newObj[key] = replaceTripshepherdWithSeeSight(input[key]);
      }
    }
    return newObj;
  }

  // Return the input if it's neither a string, object, nor array
  return input;
}

export default replaceText;
